import Head from 'next/head'
import { useEffect } from 'react'
import type { GetServerSideProps } from 'next'
import { withRequiredOrOptionalSession } from '@web-app/hoc/withSession'
import { withOnboarding } from '@web-app/hoc/withOnboarding'
import { useEventTracking } from '@web-app/hooks/useEventTracking'
import { WebLandingLayout } from '@web-app/components/layout/WebLandingLayout'
import { HeroInstantCash } from '@ui/hero/HeroInstantCash'
import {
  ATMInstantCashCopy,
  MainBlockTitleUnderline,
  MainBlockHowToStartWithInstantCash,
  MainBlockHowATMMembersAreUsingInstantCash,
  MainBlockJoinMillionsDownload,
  MainBlockFAQs,
  MainBlockTestimonials,
} from '@ui/main-blocks'
import { faqs } from '@ui/components/data'

const title =
  'ATM Instant Cash™ - Cash straight to your bank. No credit check required.'
const description =
  'Get up to $50*, no interest, no credit check. *Eligibility is based on review and approval. Not all applicants will be eligible for ATM Instant Cash™.'
const canonical = 'https://www.atm.com/'

const IndexPage = () => {
  const { trackEvent } = useEventTracking()
  const ctaUrl = '/auth/register'

  useEffect(() => {
    trackEvent('view_index', { comp: 'IndexPage' })
  }, [])

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
      </Head>
      <WebLandingLayout headerClassName="bg-blueberry-200">
        <HeroInstantCash
          className="bg-blueberry-200"
          title={
            <>
              Get up to $50* with{' '}
              <span className="sm:whitespace-pre">ATM Instant Cash™</span>
            </>
          }
          description={
            <>
              Cash straight to your bank.{' '}
              <span className="whitespace-pre">No credit check required.</span>
            </>
          }
          cta="Get Started"
          handleClickEvent={() =>
            trackEvent('click_hero_get_started', { comp: 'HeroInstantCash' })
          }
        />
        <MainBlockTitleUnderline variant="blueberry-1000" />
        <ATMInstantCashCopy amount={50} />
        <MainBlockHowToStartWithInstantCash />
        <MainBlockHowATMMembersAreUsingInstantCash />
        <MainBlockTestimonials />
        <MainBlockFAQs faqs={faqs({ amount: 50 })} />
        <MainBlockJoinMillionsDownload ctaText="Get Started" ctaUrl={ctaUrl} />
      </WebLandingLayout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps =
  withRequiredOrOptionalSession(
    { required: false },
    withOnboarding(async (_context) => {
      return { props: {} }
    }),
  )

export default IndexPage
